<template>
    <div id="mainfooter">
         <footer>
        <div class="container">
          <div class="row">
            <div class="Contact-footer">
                <div id="contact" class="d-flex align-items-center justify-content-center mb-3">
               <div> <img src="../assets/images/call.png" alt="icon" class="img-phone-footer" /></div>
                <div> 
                  <h3>+998 71 200 00 13</h3>
                </div>
              </div>
              <p>{{ translation.translate('LandingPageHome','location-info') }}</p>
              <p>{{ translation.translate('LandingPageHome','info-end') }}</p>
            
            
            <div class="theLineFooter"></div>

             <div class="social_links">
                <a href="https://www.instagram.com/faktura.uz/?hl=ru">
                    <span class="fa-stack fa-lg ig combo">
                        <i class="fa fa-circle fa-stack-2x circle"></i>
                        <i class="fa fa-instagram fa-stack-1x fa-inverse icon"></i>
                    </span>
                </a>
                <a href="https://facebook.com/fakturauz">
                    <span class="fa-stack fa-lg fb combo">
                        <i class="fa fa-circle fa-stack-2x circle"></i>
                        <i class="fa fa-facebook fa-stack-1x fa-inverse icon"></i>
                    </span>
                </a>
                <a href="https://www.youtube.com/channel/UCJwAsKcNAoX083ByjSgkBRg">
                    <span class="fa-stack fa-lg yt combo">
                        <i class="fa fa-circle fa-stack-2x circle"></i>
                        <i class="fa fa-youtube-play fa-stack-1x fa-inverse icon"></i>
                    </span>
                </a>
                <a href="https://t.me/fakturauz">
                    <span class="fa-stack fa-lg fb combo">
                        <i class="fa fa-circle fa-stack-2x circle"></i>
                        <i class="fa fa-telegram fa-stack-1x fa-inverse icon"></i>
                    </span>
                </a>
            </div>

            </div>
          </div>
        </div>
      </footer>
    </div>
</template>

<script defer>
import translate from '../../translation/translate'
export default {
  name: 'mainfooter',
  data() {
    return {
      translation: translate,
    }
  },
  components: {

  }
}
</script>
